import Modal from 'components/UI/Modal';
import React, { useState, useCallback, useEffect } from 'react';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import * as Yup from 'yup';
import {
  createAccessCode,
  determineColorToUse,
  determineDarkThemedColorToUse,
  getSingleDaySlotsForScheduleClient,
} from 'services/contributions.service';
import { useFormik } from 'formik';
import Form from 'antd/lib/form/Form';
import { uniqBy } from 'lodash';
import {
  getThemedColors,
  getClientContributionTimes,
  getCoachContributionTimes,
  getCoachClientsWithCredits,
  getOneToOneSelfBookClient,
  saveOneToOneSelfBookClient,
} from 'services/contributions.service';
import copyContributionLinkToClipboard from 'utils/copyContributionLinkToClipboard';
import SessionForm from './SessionForm';
import InvitationForm from './InvitationForm';
import InviteSent from './InviteSent';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { getTimePeriodsForAvailability, DATE_FORMATS } from 'utils/datesAndMoney';
import useAccount from 'hooks/useAccount';
import { UserRoles } from 'helpers/constants';
import Loader from 'components/UI/Loader';
import { EN_MESSAGES } from 'constants';
import ContributionForm from './ContributionForm';
import { startIncrementDurationsOneToOne } from 'pages/CreateContribution/components/OneToOneForm';

const SESSION_TITLE = 'Send a 1:1 Calendar Invite';
const INVITATION_TITLE = 'Send custom invitation';
const INVITE_SENT_TITLE = 'Waiting for client to confirm';
const FORM_INITIAL_VALUES = {
  isSessionCompleted: false,
  isInviteSent: false,
  client: '',
  email: '',
  name: '',
  date: '',
  timeSlot: '',
  price: '',
  contribution: '',
};

function ScheduleMeetingModal({
  isVisible,
  closeModal,
  selectOptions,
  paymentInfo,
  setIsScheduleMeetingVisible,
  selectedCalDate,
  title,
  fromMasterCalendar,
  isMasterCalendar = false,
}) {
  const activeContribution = useSelector(state => state.contributions?.activeContribution);
  // const { sessionDuration, startTimeIncrementDuration } = useSelector(s => s?.oneToOneData);
  const [formTitleAndButton, setFormTitleAndButton] = useState({
    titleText: title || SESSION_TITLE,
    buttonText: 'Next',
  });
  const contribution = useContribution();
  const { currentRole } = useAccount();
  const isCoach = currentRole === UserRoles.cohealer;
  const [isExistingClient, setIsExistingClient] = useState(true);
  const [selectedDate, setSelectedDate] = useState();
  const [emailsList, setEmailsList] = useState('');
  const [toggleAvailabilitySlots, setToggleAvailabilitySlots] = useState(true);
  const { themedColor } = getThemedColors(contribution);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [meetingForm, setMeetingForm] = useState(FORM_INITIAL_VALUES);
  const [pricingOptions, setPricingOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const request = toggleAvailabilitySlots ? getCoachContributionTimes : getSingleDaySlotsForScheduleClient;
  const [tempTimeSlots, setTempTimeSlots] = useState([]);
  const [clientsWithCredits, setClientsWithCredits] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState('');
  const [filteredClientArray, setfilteredClientArray] = useState([]);
  const [clientsArray, setclientsArray] = useState([]);
  const [code, setCode] = useState(null);
  const [packageClient, setpackageClient] = useState([]);
  const [closeDropDown, setCloseDropDown] = useState(false);
  const [pricingOption, setpricingOption] = useState(null);
  const [hasContributionPreselected, setHasContributionPreselected] = useState(Boolean(activeContribution?.id));
  const isDarkThemeEnabled = determineDarkThemedColorToUse(activeContribution);
  const [disableContributionSelection, setDisableContributionSelection] = useState(false);
  const [clientData, setClientData] = useState(null);
  const onOptionSelect = useCallback(val => {
    setCloseDropDown(val);
  }, []);

  const clickAnywhere = useCallback(() => {
    setCloseDropDown(true);
  }, []);

  useEffect(() => {
    if (formTitleAndButton.titleText === 'Waiting for client to confirm') {
      setTimeout(() => {
        onSubmitConfirmation();
        setFormTitleAndButton({
          titleText: SESSION_TITLE,
          buttonText: 'Next',
        });
      }, 4000);
    }
  }, [formTitleAndButton]);
  useEffect(() => {
    formik.setErrors({});
    formik.setFieldValue('date', selectedCalDate);
  }, []);

  const onSelectTimeSlot = slotId => {
    const tempSlots = tempTimeSlots.filter(slot => {
      if (slot.id === slotId) {
        slot.isSelected = true;
      } else {
        slot.isSelected = false;
      }
      return slot;
    });
    setTempTimeSlots(tempSlots);
  };
  const currentDate = moment();
  const onChangeClient = useCallback(
    clientId => {
      filterArrayforPackageCredit(clientId);
      setSelectedClient(clientId);
      formik.setFieldValue('client', clientId);
      setMeetingForm({
        ...meetingForm,
        clientName: getSelectedClient(clientId),
      });

      if (fromMasterCalendar) {
        if (pricingOption != null) {
          calculatePricingsforMasterCalendar(pricingOption.paymentInfo);
          filterArrayforPackageCredit();
          getCoachClientsWithCredits(pricingOption.id).then(clientsList =>
            mapClientsListMasterCalendar(clientsList, pricingOption.paymentInfo),
          );
        }
      }
    },
    [selectedClient, pricingOption],
  );

  // const onChangeClient = clientId => {
  //   filterArrayforPackageCredit(clientId);
  //   setSelectedClient(clientId);
  //   formik.setFieldValue('client', clientId);
  //   setMeetingForm({
  //     ...meetingForm,
  //     clientName: getSelectedClient(clientId),
  //   });
  // };

  let colorToUse = determineColorToUse(activeContribution);
  const formik = useFormik({
    initialValues: {
      client: '',
      name: '',
      email: '',
      date: moment(),
      coachSessionTitle: '',
      price: '',
    },
    validationSchema: Yup.object().shape({
      client: isExistingClient ? Yup.string().required('Client is required') : Yup.string(),
      name: !isExistingClient ? Yup.string().required('Name is required') : Yup.string(),
      email: !isExistingClient && Yup.string().email('Invalid email').required('Email is required'),
      price: meetingForm?.isSessionCompleted ? Yup.string().required('Price is required') : Yup.string(),
    }),
    onSubmit: values => onSubmitForm(values),
  });

  const mapClientsList = (clientsList = []) => {
    const selectOptions = [];
    const { cost, paymentOptions, packageSessionNumbers, monthlySessionSubscriptionInfo } = paymentInfo;
    if (paymentOptions.length === 1 && paymentOptions.includes('FreeSessionsPackage')) {
      clientsList.map(participant => {
        const clientName = participant.clientName;
        const creditsRemaining = participant.clientName.split('(')[1];
        if (participant.creditsRemaining > 0) {
          selectOptions.push({
            title: (
              <>
                <p style={{ margin: 0 }}>
                  {clientName}{' '}
                  {participant.creditsRemaining > 0 && <span style={{ color: 'gray' }}>({creditsRemaining}</span>}
                </p>
              </>
            ),
            clientName: clientName,
            clientEmail: participant.clientEmail,
            creditsRemaining: participant.creditsRemaining,
            value: participant.clientId,
            key: participant.clientId,
          });
        }
      });
    } else {
      clientsList.map(participant => {
        const clientName = participant.clientName;
        const creditsRemaining = participant.clientName.split('(')[1];
        selectOptions.push({
          title: (
            <>
              <p style={{ margin: 0 }}>
                {clientName}{' '}
                {participant.creditsRemaining > 0 && <span style={{ color: 'gray' }}>({creditsRemaining}</span>}
              </p>
            </>
          ),
          clientName: clientName,
          clientEmail: participant.clientEmail,
          creditsRemaining: participant.creditsRemaining,
          value: participant.clientId,
          key: participant.clientId,
        });
      });
    }
    setClientsWithCredits(selectOptions);
    setclientsArray(clientsList);
  };

  const mapClientsListMasterCalendar = (clientsList = [], val) => {
    const selectOptions = [];
    const { cost, paymentOptions, packageSessionNumbers, monthlySessionSubscriptionInfo } = val;
    if (paymentOptions.length === 1 && paymentOptions.includes('FreeSessionsPackage')) {
      clientsList.map(participant => {
        const clientName = participant.clientName;
        const creditsRemaining = participant.clientName.split('(')[1];
        if (participant.creditsRemaining > 0) {
          selectOptions.push({
            title: (
              <>
                <p style={{ margin: 0 }}>
                  {clientName}{' '}
                  {participant.creditsRemaining > 0 && <span style={{ color: 'gray' }}>({creditsRemaining}</span>}
                </p>
              </>
            ),
            clientName: clientName,
            clientEmail: participant.clientEmail,
            creditsRemaining: participant.creditsRemaining,
            value: participant.clientId,
            key: participant.clientId,
          });
        }
      });
    } else {
      clientsList.map(participant => {
        const clientName = participant.clientName;
        const creditsRemaining = participant.clientName.split('(')[1];
        selectOptions.push({
          title: (
            <>
              <p style={{ margin: 0 }}>
                {clientName}{' '}
                {participant.creditsRemaining > 0 && <span style={{ color: 'gray' }}>({creditsRemaining}</span>}
              </p>
            </>
          ),
          clientName: clientName,
          clientEmail: participant.clientEmail,
          creditsRemaining: participant.creditsRemaining,
          value: participant.clientId,
          key: participant.clientId,
        });
      });
    }
    setClientsWithCredits(selectOptions);
    setclientsArray([{ creditsRemaining: selectedClient.creditsRemaining, clientId: selectedClient?.value }]);
    calculatePricingsforMasterCalendar(pricingOption.paymentInfo);
  };
  const filterClient = (clientsList = []) => {
    if (isMasterCalendar === false) {
      calculatePricings(paymentInfo);
    }
  };
  const handleCopyToClipboard = useCallback(
    event => {
      if (event) event.preventDefault();
      copyContributionLinkToClipboard({ contributionId: contribution.id, code: code });
      setIsTooltipOpen(true);
    },
    [contribution.id, code],
  );
  const onSubmitForm = values => {
    values.timeSlot = getSelectedTimeSlot().completeTime;
    if (!meetingForm?.isSessionCompleted) {
      if (!onSubmitSessionForm(values)) {
        return;
      }
    }
    if (meetingForm?.isSessionCompleted && !meetingForm?.isInviteSent) {
      if (!onSubmitSentInviteForm(values)) {
        return;
      }
    }
    if (meetingForm?.isSessionCompleted && meetingForm?.isInviteSent) {
      onSubmitConfirmation();
    }
  };

  const onSubmitSessionForm = values => {
    if (isExistingClient) {
      values.client = isExistingClient ? values.client : '';
    }
    setMeetingForm({
      ...meetingForm,
      ...values,
      isSessionCompleted: true,
    });
    setFormTitleAndButton({
      titleText: INVITATION_TITLE,
      buttonText: 'Send Invitation',
    });
    return true;
  };

  const onSubmitSentInviteForm = values => {
    setIsLoading(false);
    const { clientName, value } = selectedClient;
    let tempValues;
    let accessCode;
    if (formik.values?.price?.value.toString() === 'Free') {
      createAccessCode(activeContribution.id).then(data => {
        accessCode = data.code;
        if (emailsList.length > 0) {
          tempValues = {
            // clientId: selectedClient,
            availabilityTimeId: getSelectedTimeSlot().id,
            offset: getSelectedTimeSlot().offset,
            selectedSlotEndDateTime: getSelectedTimeSlot().end,
            selectedSlotStartDateTime: getSelectedTimeSlot().start,
            email: emailsList,
            priceOption: `${formik.values?.price?.value}`,
            clientName: meetingForm.name,
            contributionId: activeContribution.id,
            sendViaEmail: true,
            sharedViaLink: false,
            accessCode: data.code,
            sessionName: formik.values.coachSessionTitle,
          };
        } else {
          tempValues = {
            clientId: value,
            availabilityTimeId: getSelectedTimeSlot().id,
            offset: getSelectedTimeSlot().offset,
            selectedSlotEndDateTime: getSelectedTimeSlot().end,
            selectedSlotStartDateTime: getSelectedTimeSlot().start,
            email: null,
            priceOption: `${formik.values?.price?.value}`,
            clientName: clientName.match(/^(.*?)\s*\((.*?)\)\s*(.*)$/)
              ? clientName?.match(/^(.*?)\s*\((.*?)\)\s*(.*)$/)[1]
              : clientName,
            contributionId: activeContribution.id,
            sendViaEmail: false,
            sharedViaLink: false,
            accessCode: data.code,
            sessionName: formik.values.coachSessionTitle,
          };
        }
        onSubmitInvitationForm(tempValues);
      });
    } else {
      if (emailsList.length > 0) {
        tempValues = {
          clientId: null,
          availabilityTimeId: getSelectedTimeSlot().id,
          offset: getSelectedTimeSlot().offset,
          selectedSlotEndDateTime: getSelectedTimeSlot().end,
          selectedSlotStartDateTime: getSelectedTimeSlot().start,
          email: emailsList,
          priceOption: `${formik.values?.price?.value}`,
          clientName: meetingForm.name,
          contributionId: activeContribution.id,
          sendViaEmail: true,
          sharedViaLink: false,
          accessCode: null,
          sessionName: formik.values.coachSessionTitle,
        };
      } else {
        tempValues = {
          clientId: value,
          availabilityTimeId: getSelectedTimeSlot().id,
          offset: getSelectedTimeSlot().offset,
          selectedSlotEndDateTime: getSelectedTimeSlot().end,
          selectedSlotStartDateTime: getSelectedTimeSlot().start,
          email: null,
          priceOption: `${formik.values?.price?.value}`,
          clientName: clientName.match(/^(.*?)\s*\((.*?)\)\s*(.*)$/)
            ? clientName?.match(/^(.*?)\s*\((.*?)\)\s*(.*)$/)[1]
            : clientName,
          contributionId: activeContribution.id,
          sendViaEmail: false,
          sharedViaLink: false,
          accessCode: null,
          sessionName: formik.values.coachSessionTitle,
        };
      }
      onSubmitInvitationForm(tempValues);
    }

    setMeetingForm({
      ...meetingForm,
      ...values,
      isInviteSent: true,
    });

    setFormTitleAndButton({
      titleText: INVITE_SENT_TITLE,
      buttonText: 'Ok',
    });

    setIsLoading(true);
    return true;
  };

  const onSubmitConfirmation = () => {
    onCloseModal();
  };

  const getSelectedClient = client => {
    setClientData(client);
    const selectedClient = clientsWithCredits?.find(option => option?.value === client);
    return selectedClient?.clientName;
  };

  const getSelectedTimeSlot = () => {
    const selectedTimeSlot = tempTimeSlots.find(slot => slot.isSelected);

    return selectedTimeSlot;
  };

  const onBackButton = () => {
    if (meetingForm.isSessionCompleted && meetingForm.isInviteSent) {
      closeModal();
    }
    setFormTitleAndButton({
      titleText: SESSION_TITLE,
      buttonText: 'Next',
    });
    setMeetingForm({ ...meetingForm, isSessionCompleted: false });
  };

  useEffect(() => {
    if (isMasterCalendar) {
      getCoachContributionTimes(activeContribution?.id).then(res => {
        const startTimes = res.map(item => item.startTime);
        // Extract date from start times using Moment.js
        // const dates = startTimes.map(startTime => moment(startTime).format('YYYY-MM-DD'));
        const filteredDates = startTimes?.filter(date => {
          const dateObj = moment(date);
          return dateObj.isSameOrAfter(currentDate, 'day');
        });
        timeSlotsOnAvailbleDate(filteredDates[0]);
      });
    }
    if (!activeContribution) setIsModalOpen(true);
    if (isVisible && activeContribution && !isMasterCalendar) {
      timeSlotsOnAvailbleDate(selectedCalDate);
    }
    if (!clientsArray?.length && !isMasterCalendar) {
      getClientsList();
    }
  }, [isVisible, activeContribution]);

  useEffect(() => {
    if (!isMasterCalendar) {
      timeSlotsOnAvailbleDate(selectedCalDate || formik.values.date);
    } else {
      getCoachContributionTimes(activeContribution?.id).then(res => {
        const startTimes = res.map(item => item.startTime);
        // Extract date from start times using Moment.js
        // const dates = startTimes.map(startTime => moment(startTime).format('YYYY-MM-DD'));
        const filteredDates = startTimes?.filter(date => {
          const dateObj = moment(date);
          return dateObj.isSameOrAfter(currentDate, 'day');
        });
        timeSlotsOnAvailbleDate(formik.values.date);
      });
    }
  }, [toggleAvailabilitySlots]);

  const getClientsList = () => {
    getCoachClientsWithCredits(activeContribution.id).then(clientsList => mapClientsList(clientsList));
  };

  const onSubmitInvitationForm = data => {
    saveOneToOneSelfBookClient(data).then(response => {
      setIsLoading(false);
    });
  };

  const onCloseModal = () => {
    setIsScheduleMeetingVisible(false);
    setIsModalOpen(false);
    setSelectedClient('');
    setFormTitleAndButton({
      titleText: SESSION_TITLE,
      buttonText: 'Next',
    });
    formik.resetForm();
    setMeetingForm(FORM_INITIAL_VALUES);
    setEmailsList('');
  };

  const timeSlotsOnAvailbleDate = val => {
    const selectedDate = val ? val : formik.values.date;
    // formik.setFieldValue('date',val)
    const duration = activeContribution?.durations;
    const serviceProviderName = activeContribution?.serviceProviderName;
    setIsModalOpen(true);
    setIsLoading(true);
    // const increments = activeContribution?.sessionIncrements || [];
    //const sessionDuration = activeContribution?.durations?.[0];
    const startTimeIncrementDuration = activeContribution?.sessionIncrements?.[0];
    const increments = [startTimeIncrementDuration];
    // if (startTimeIncrementDuration > 0) {
    //   for (let i = 0; i < Math.ceil(sessionDuration / startTimeIncrementDuration); i++) {
    //     increments.push(i * startTimeIncrementDuration);
    //   }
    // }
    // if (increments.every(k => k !== 0)) {
    //   increments.push(0);
    // }
    if (moment(selectedDate).isSameOrAfter(moment(), 'day')) {
      Promise.allSettled(
        increments.map(k => request(activeContribution.id, k, moment(selectedDate).format('YYYY-MM-DD'))),
      ).then(_slots => {
        const zeroSlots = _slots.reduce(
          (acc, curr) => (acc.some(k => k.startTime === curr.value?.startTime) ? acc : [...acc, ...curr.value]),
          [],
        );
        const formattedSlots = zeroSlots
          .filter(slot => moment(slot.startTime).isSame(selectedDate, 'day'))
          .sort((a, b) => moment(a.startTime).diff(moment(b.startTime), 'minutes'));

        let newArray = formattedSlots.filter(function (el) {
          return el.bookedTimes.length === 0;
        });
        const filteredData = newArray.filter(item => {
          const startTime = moment(item.startTime);
          if (startTime.isSame(currentDate, 'day')) {
            return startTime.isSame(currentDate, 'day') && !startTime.isBefore(currentDate);
          } else {
            return startTime;
          }
        });
        const events = getTimePeriodsForAvailability({
          availabilityPeriods: uniqBy(filteredData, 'id'),
          duration,
          serviceProviderName,
        });
        if (events.length === 0) {
          setTempTimeSlots([]);
          setIsLoading(false);
        }
        if (events.length > 0) {
          let timeSlotsOption = mapTimeSlots(events);
          // timeSlotsOption[0].isSelected = true;
          setTempTimeSlots(timeSlotsOption);
          setSelectedDate(moment(formik.values.date)._d);
          setIsLoading(false);
          setIsModalOpen(true);
        }
      });
    } else {
      setTempTimeSlots([]);
      setIsLoading(false);
    }
  };

  const mapTimeSlots = timeSlots => {
    if (timeSlots?.length > 0) {
      const newTimeSlots = timeSlots.map((time, index) => {
        // if (time?.isBooked===undefined)
        return {
          id: time.id,
          completeTime: time.title,
          startTime: moment(time.start).format('h:mm AM'),
          endTime: time.end,
          start: moment(time.start),
          end: moment(time.end),
          isSelected: index === 0 ? true : false,
          offset: time.offset,
          time: time.start,
        };
      });
      return newTimeSlots;
    } else {
      return [];
    }
  };

  const filterArrayforPackageCredit = clientId => {
    let filter = clientsArray.filter(
      participant => clientId?.value === participant?.clientId && participant?.creditsRemaining > 0,
    );

    setpackageClient(filter);
  };
  const calculatePricings = () => {
    const { cost, paymentOptions, packageSessionNumbers, monthlySessionSubscriptionInfo } = paymentInfo;
    const priceSelectOptions = [];

    const paymentTitle = {
      Free: 'Single Session (Free)',
      PerSession: 'Single Session',
    };

    if (paymentOptions.includes('Free')) {
      priceSelectOptions.push({
        value: 'Free',
        price: 0,
        title: paymentTitle.Free,
      });
    }
    if (
      paymentOptions.includes('PerSession') &&
      (selectedClient.creditsRemaining === 0 || isExistingClient === false)
    ) {
      priceSelectOptions.push({
        value: cost,
        price: cost,
        title: `${paymentTitle.PerSession} ($${cost} USD)`,
      });
    }

    if (selectedClient.creditsRemaining > 0 && isExistingClient) {
      priceSelectOptions.push({
        value: 'Credit',
        price: 'Free',
        title: `Single Session (Free/Package Credit)`,
      });
    }
    setPricingOptions(priceSelectOptions);
  };

  const calculatePricingsforMasterCalendar = val => {
    const { cost, paymentOptions, packageSessionNumbers, monthlySessionSubscriptionInfo } = val;
    const priceSelectOptions = [];

    const paymentTitle = {
      Free: 'Single Session (Free)',
      PerSession: 'Single Session',
    };

    if (paymentOptions.includes('Free')) {
      priceSelectOptions.push({
        value: 'Free',
        price: 0,
        title: paymentTitle.Free,
      });
    }
    if (paymentOptions.includes('PerSession') && selectedClient.creditsRemaining === 0) {
      priceSelectOptions.push({
        value: cost,
        price: cost,
        title: `${paymentTitle.PerSession} ($${cost} USD)`,
      });
    }

    if (selectedClient.creditsRemaining > 0 && isExistingClient) {
      priceSelectOptions.push({
        value: 'Credit',
        price: 'Free',
        title: `Single Session (Free/Package Credit)`,
      });
    }
    setPricingOptions(priceSelectOptions);
  };

  useEffect(() => {
    if (fromMasterCalendar) {
      if (pricingOption != null) {
        calculatePricingsforMasterCalendar(pricingOption.paymentInfo);
        filterArrayforPackageCredit();
        getCoachClientsWithCredits(pricingOption.id).then(clientsList =>
          mapClientsListMasterCalendar(clientsList, pricingOption.paymentInfo),
        );
      }
    }
  }, [pricingOption, onChangeClient, isExistingClient]);

  useEffect(() => {
    if (
      (!meetingForm.isSessionCompleted && !meetingForm.isInviteSent) ||
      (meetingForm.isSessionCompleted && meetingForm.isInviteSent)
    ) {
      setFormTitleAndButton({ titleText: INVITATION_TITLE, buttonText: 'Next' });
    }
  }, [meetingForm]);

  return isLoading && !isModalOpen ? (
    <Loader />
  ) : (
    <Modal
      isOpen={isModalOpen}
      title={formTitleAndButton.titleText}
      onSubmit={values => {
        formik.handleSubmit(values);
      }}
      isCreatingContribution={fromMasterCalendar || isMasterCalendar}
      submitTitle={formTitleAndButton.buttonText}
      cancelTitle={'Back'}
      hiddenCancel={
        !meetingForm.isSessionCompleted && !meetingForm.isInviteSent
          ? true
          : meetingForm.isSessionCompleted && !meetingForm.isInviteSent
          ? false
          : meetingForm.isSessionCompleted && meetingForm.isInviteSent
          ? true
          : false
      }
      onCancel={() => onCloseModal()}
      onBack={() => onBackButton()}
      isBackButton={true}
      onClickAnyWhere={clickAnywhere}
      brandingColor={isMasterCalendar ? false : true}
      isScheduleMeetingModal
      widthRequired="80%"
      zIndex="1250"
    >
      <div
        style={
          isMasterCalendar
            ? {
                fontFamily: 'Avenir',
                fontSize: '14px',
                fontWeight: '900',
                marginBottom: '20px',
                color: 'black',
              }
            : {
                fontFamily: 'Avenir',
                fontSize: '14px',
                fontWeight: '900',
                marginBottom: '20px',
                color: isDarkThemeEnabled ? 'white' : 'black',
              }
        }
      >
        By completing the information, you will send your 1:1 client an invitation to their email that they’ll be able
        to accept or deny.
      </div>
      <div
        className="schedule-meeting-form-modal"
        onClick={() => {
          setCloseDropDown(true);
        }}
      >
        <Form id="meetingForm">
          {!meetingForm?.isInviteSent && fromMasterCalendar && (
            <ContributionForm
              setpricingOption={setpricingOption}
              pricingOption={pricingOption}
              calculatePricings={calculatePricings}
              contributionSelectionDisable={disableContributionSelection}
            />
          )}
          {!meetingForm?.isSessionCompleted && activeContribution?.id && (
            <SessionForm
              selectedCalDate={selectedCalDate}
              formik={formik}
              isExistingClient={isExistingClient}
              setIsExistingClient={setIsExistingClient}
              emailsList={emailsList}
              selectOptions={clientsWithCredits}
              setToggleAvailabilitySlots={setToggleAvailabilitySlots}
              toggleAvailabilitySlots={toggleAvailabilitySlots}
              setEmailsList={setEmailsList}
              colorToUse={colorToUse}
              onChangeClient={onChangeClient}
              selectedClient={selectedClient}
              onChangeDate={timeSlotsOnAvailbleDate}
              onSelectTimeSlot={onSelectTimeSlot}
              tempTimeSlots={tempTimeSlots}
              themedColor={themedColor}
              isLoading={isLoading}
              setMeetingForm={setMeetingForm}
              filterClient={filterClient}
              paymentInfo={paymentInfo}
              onSubmitConfirmation={onSubmitConfirmation}
              onOptionSelect={onOptionSelect}
              closeDropDown={closeDropDown}
              setDisableContributionSelection={setDisableContributionSelection}
              isMasterCalendar={isMasterCalendar}
            />
          )}
          {meetingForm?.isSessionCompleted && !meetingForm?.isInviteSent && activeContribution?.id && (
            <InvitationForm
              isMasterCalendar={isMasterCalendar}
              colorToUse={colorToUse}
              isLoading={isLoading}
              isExistingClient={isExistingClient}
              setIsExistingClient={setIsExistingClient}
              emailsList={emailsList}
              selectClientOptions={clientsWithCredits}
              setEmailsList={setEmailsList}
              formik={formik}
              closeModal={closeModal}
              meetingForm={meetingForm}
              handleCopyToClipboard={handleCopyToClipboard}
              setIsModalOpen={setIsModalOpen}
              setFormTitleAndButton={setFormTitleAndButton}
              isTooltipOpen={isTooltipOpen}
              selectOptions={pricingOptions}
              themedColor={themedColor}
              onChangeClient={onChangeClient}
              selectedClient={selectedClient}
              setIsTooltipOpen={setIsTooltipOpen}
              filterClient={filterClient}
              setMeetingForm={setMeetingForm}
              onSubmitConfirmation={onSubmitConfirmation}
              tempTimeSlots={tempTimeSlots}
              setCode={setCode}
              onOptionSelect={onOptionSelect}
              closeDropDown={closeDropDown}
              paymentInfo={paymentInfo}
              code={code}
              setClientData={setClientData}
              clientData={clientData}
              setDisableContributionSelection={setDisableContributionSelection}
            />
          )}
          {meetingForm?.isInviteSent && (
            <InviteSent isDarkThemeEnabled={isMasterCalendar ? false : isDarkThemeEnabled} />
          )}
        </Form>
      </div>
    </Modal>
  );
}

export default ScheduleMeetingModal;
